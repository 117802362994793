<template>
  <v-footer
    id="dashboard-core-footer"
    class="secondary footer pb-12"

    width="100vw"
  >
    <v-container class="pb-0 mb-0">
      <v-row class="d-flex justify-center mt-2 pb-2">
        <v-col cols="11">
          <logo />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-col
          cols="12"
          class="d-inline-flex align-center justify-end"
        >
          <v-col
            cols="7"
            class="d-flex justify-start pl-14"
          >
            <div class="footer-text2">
              ©{{ this.currentYear }} {{ regionalCompanyName }} DBA Blanket Insurance &copy;
              Blanket.com {{ siteVersion() }}
            </div>
          </v-col>
          <v-col
            cols="5"
            class="d-flex justify-end ml-2 pr-0"
          >
            <!-- <v-btn
              v-if="!isUserLoggedIn"
              text
              class="hidden-sm-and-down"
              to="/pages/login"
            >
              <span class="login">{{$t('loginPage.loginInAccount')}}</span>
            </v-btn> -->
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider
            horizontal
            class="white"
          ></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="d-inline-flex pb-0"
        >
          <v-col
            cols="10"
            class="d-inline-flex align-center ml-2 pb-0"
          >
            <v-menu
              v-model="aboutMenuValue"
              offset-y
              origin="bottom right"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="footer-items c-btn-padding"
                  color="#FFFFFF"
                  text
                  v-bind="attrs"
                  :class="{ active: aboutMenuValue }"
                  v-on="on"
                >
                  {{ $t('menu.aboutUs') }}
                  <v-icon
                    right
                    class="white--text"
                  >
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in generateAboutItems"
                  :key="index"
                  class="blanketv2accent--text"
                  :to="item.route"
                >
                  <v-list-item-title>{{ $t(`menu.${toCamelCase(item.title)}`) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              text
              to="/contactus"
              class="mb-2 c-bg-color c-btn-padding link-style"
            >
              <span class="footer-items">
                {{ $t('menu.contactUs') }}
              </span>
            </v-btn>
            <v-btn
              text
              target="_blank"
              :to="userCanadian ? '/privacy-ca' : '/privacy'"
              class="mb-2 c-bg-color c-btn-padding"
            >
              <span class="footer-items">{{ $t('menu.privacyPolicy') }}</span>
            </v-btn>
            <v-btn
              v-if="userCanadian"
              text
              target="_blank"
              to="/complaints-ca"
              class="mb-2 c-bg-color c-btn-padding link-style"
            >
              <span class="footer-items">{{ $t('menu.complaints') }}</span>
            </v-btn>
          </v-col>
          <v-col
            cols="2"
            class="d-inline-flex justify-end"
          >
            <!-- <v-icon
              class="px-2"
              color="white"
            >
              mdi-facebook
            </v-icon> -->
            <v-btn
              text
              small
              href="https://www.linkedin.com/company/applywithblanket/"
              target="_blank"
              class="px-0"
              color="transparent"
            >
              <v-icon
                class="px-2 mt-3"
                color="white"
              >
                mdi-linkedin
              </v-icon>
            </v-btn>
            <!-- <v-icon
              class="px-2"
              color="white"
            >
              mdi-twitter
            </v-icon>
            <v-icon
              class="px-2"
              color="white"
            >
              mdi-youtube
            </v-icon>
            <v-icon
              class="px-2"
              color="white"
            >
              mdi-instagram
            </v-icon> -->
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider
            horizontal
            class="white"
          ></v-divider>
        </v-col>
      </v-row>
      <v-row class="secondary mt-0">
        <v-col class="white--text pb-0 mb-0 pt-0 tiny-font">
          <p
            v-for="(item,index) in $t('disclaimer')"
            :key="index"
          >
            {{ item }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  import { toCamelCase } from '../../../../utils/normalizeToEnglish'
  import Logo from './Logo'
  import moment from 'moment'
  import { siteVersion } from '../../../../i18n'
  export default {
    name: 'DashboardCoreFooter',
    components: {
      Logo
    },
    data: () => ({
      productMenuValue: null,
      aboutMenuValue: null,
      links: [
        {
          href: '#',
          text: 'About Us',
        },
        {
          href: '#',
          text: 'News',
        },
        {
          href: '#',
          text: 'Licenses',
        },
      ],
      aboutitems: [
        { title: 'Our Team', route: '/about/team' },
        { title: 'Partnerships', route: '/about/partnerships' },
      // { title: 'News', route: '/about/news' },
      ],
      menuitems: [
        // { title: 'Main', route: '/' },
        { title: 'Pet', route: '/pet' },
        // { title: 'Auto', route: '/auto' },
        // { title: 'US Term Life', route: '/afficiencyterm' },
        // { title: 'Homeowners', route: '/homeowners' },
        // { title: 'Term Life', route: '/termlifehome' },
        { title: 'Travel', route: '/travel/usa' },
        // { title: 'Canadian Term', route: '/canadianterm' },
        // { title: 'Canadian Term R&C', route: '/canadiantermrc' },
        { title: 'Renters', route: '/renters' },
      // { title: 'Landlord', route: '/landlord' },
      // { title: 'Condo', route: '/condo' },
      // { title: 'Business', route: '/business' },
      ],
      currentYear: moment().format('YYYY')
    }),
    computed: {
      userCanadian () {
        const getCountry = this.$store.getters.getWebsiteLocation || this.$Storage.getCookie('location')
        return getCountry === 'CA'
      },
      regionalCompanyName () {
        if (this.$store.getters.getWebsiteLocation === 'CA') {
          return '10555193 Canada Inc.'
        }
        return 'Yeroosha Inc'
      },
      generateAboutItems () {
        let user = this.$store.getters.user
        let items = [
          { title: 'Our Team', route: '/about/team' },
          { title: 'Partnerships', route: '/about/partnerships' },
          {
            title: 'GGA Create Partnerships',
            route: '/about/ggacreatepartnership',
          },
        ]
        if (user && user.status === 'admin') {
          items.push({ title: 'Admin Reports', route: '/adminreports/policies', id: 'adminpolicy' })
          items.push({ title: 'Admin Partnerships', route: '/adminpartners', id: 'adminpartners' })
          items.push({ title: 'Manage Users', route: '/manage/users', id: 'adminusers' })
        }
        return items
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      isUserLoggedIn () {
        if (
          this.$store.getters.getuser !== null &&
          this.$store.getters.getuser !== ''
        ) {
          // console.log('isUserLoggedIn TRUE')
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      siteVersion () {
        return siteVersion
      },
      toCamelCase,
      navigate (route) {
        this.$router.push(route)
      },
    },
  }
</script>

<style scoped>

.tiny-font {
  font-size: 10px;
}
.footer-text {
  color: white;
  text-decoration: none;
  font-size: 20px;
  line-height: 23px;
}
a {
  text-decoration: none;
}
a {
  text-decoration: none;
}
.footer-text2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.login {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  text-transform: capitalize;

  color: #ffffff;
}

.btn {
  text-transform: none !important;
  font-size: 20px;
  font-weight: 500;
}

.ins-btn {
  font-size: 17px;
  font-weight: 500;
  text-transform: none !important;
}

.tabs {
  background-color: #005f6c;
  color: white;
}

.footer-items {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  text-transform: capitalize;
  text-decoration: none;
  color: #ffffff;
}

@media screen and (max-width: 960px) {
  .footer-items {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 762px) {
  .footer-items {
    font-size: 12px !important;
  }
  .v-btn.v-size--default.c-btn-padding {
    padding: 0 20px !important;
  }
}

.v-btn.active .v-icon {
  transform: rotate(-180deg);
}

.c-bg-color:hover::before {
  background-color: white;
}
@media screen and (min-width: 600px) and (max-width: 762px) {
.link-style{
  position: relative;
  top:4px;
}
}
</style>
<style lang="sass">
#pages-core-footer
  a
    color: #FFFFFF
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
